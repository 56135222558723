.__common_model_class {
    width: 100vw !important;
    height: 100vh !important;
    background: #fff;
    top: 0;
    left: 0;
    max-width: 100vw;
    padding: 0;

}

.arabic {
    .__common_model_class {
        .ant-modal-close {
            left: auto !important;
        }
    }
}

.__common_model_class .ant-modal-content {
    width: 100%;
    box-shadow: none;
    height: 100%;
    background-size: cover;
    border-radius: 0px;
    overflow: hidden;

}


.__common_model_class .ant-modal-body {
    padding: 0 !important;

}