
::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #1E1E1E 0%, rgba(30, 30, 30, 0.00) 100%);
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(267deg, #6200E9 1.18%, #CE92EB 102.3%), #D9D9D9;
    border-radius: 20px;
    cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6200E9;
}

.arabic{
    font-family: "Lateef", serif !important;
}

body{
    font-family: "Montserrat", sans-serif;
}


.react-player {
    left: 0;
    position: absolute;
    top: 0;
}

.player-wrapper {
    background: #000;
    border: 2px solid #fff;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}

.__photos-list img, .player-wrapper {
    border-radius: 10px;
    box-shadow: 0 0 10px #0005;
}